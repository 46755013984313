'use client';

import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { Eye, EyeOff } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from 'sonner';
import { Icons } from '@/components/icons';
import { useState } from 'react';
import { type SignInResource, type SetActive } from '@clerk/types';
import { useRouter, useSearchParams } from 'next/navigation';
import { isClerkAPIResponse } from '@/types/clerk';
import { errorMessage } from '@/utils/form';
import {
  type UserLoginFormData,
  userLoginSchema,
} from '@/lib/validations/auth';
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';

export function UserLoginForm({
  signIn,
  setActive,
}: {
  signIn: SignInResource;
  setActive: SetActive;
}) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url') || '/';

  const methods = useForm<UserLoginFormData>({
    resolver: zodResolver(userLoginSchema),
  });

  const { control, handleSubmit, setError } = methods;

  const onSubmit = async (data: UserLoginFormData) => {
    setIsLoading(true);
    try {
      const { email: identifier, password } = data;

      const result = await signIn.create({
        identifier,
        password,
      });

      if (result.status === 'complete') {
        await setActive({ session: result.createdSessionId });
        // Redirect to /id with the redirect_url query parameter
        router.push(`/id?redirect_url=${encodeURIComponent(redirectUrl)}`);
      } else {
        setIsLoading(false);
      }
      return;
    } catch (err) {
      setIsLoading(false);
      console.error(JSON.stringify(err, null, 2));

      if (isClerkAPIResponse(err)) {
        err.errors.forEach((clerkError) => {
          const { meta } = clerkError;
          if (meta && meta.paramName) {
            setError(meta.paramName as keyof UserLoginFormData, {
              type: 'manual',
              message: errorMessage(clerkError),
            });
            if (!Object.keys(userLoginSchema).includes(meta.paramName)) {
              toast.error('Something went wrong.', {
                description:
                  'Your request failed. Please try again or contact support.',
              });
            }
          }
        });
      } else {
        toast.error('Something went wrong.', {
          description:
            'Your request failed. Please try again or contact support.',
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-1">
          <FormField
            control={control}
            name="email"
            render={({ field, ...rest }) => {
              return (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="user@yourcompany.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="••••••••"
                      {...field}
                      className="pr-10" // Add padding to account for icon
                    />
                    <div
                      className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" disabled={isLoading} className="mt-4">
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Login
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
